import type {
  DehydratedState,
  VueQueryPluginOptions,
} from '@tanstack/vue-query';
import {
  VueQueryPlugin,
  QueryClient,
  QueryCache,
  hydrate,
  dehydrate,
} from '@tanstack/vue-query';
import { toast } from 'vue3-toastify';
import { defineNuxtPlugin, useState } from '#imports';

export default defineNuxtPlugin((nuxt) => {
  const vueQueryState = useState<DehydratedState | null>('vue-query');
  const { $i18n } = useNuxtApp();

  // Modify your Vue Query global settings here
  const queryClient = new QueryClient({
    defaultOptions: { queries: { staleTime: 5000 } },
    queryCache: new QueryCache({
      onError: (_, query) => {
        if  (typeof window == 'undefined')
          return;
        const message = query?.meta && 'errorMessage' in query.meta
          ? query.meta.errorMessage as string
          : 'common.somethingwentwrong';
        const useDefault = query?.meta && 'useDefaultErrorHandler' in query.meta
          ? !!query.meta.useDefaultErrorHandler
          : true;

        if (useDefault) {
          toast($i18n.t(message), {
            autoClose: 2000,
            type: 'error',
            theme: 'dark',
          });
        }
      }
    })
  });
  const options: VueQueryPluginOptions = { queryClient };

  nuxt.vueApp.use(VueQueryPlugin, options);

  if (import.meta.server) {
    nuxt.hooks.hook('app:rendered', () => {
      vueQueryState.value = dehydrate(queryClient);
    });
  }

  if (import.meta.client) {
    nuxt.hooks.hook('app:created', () => {
      hydrate(queryClient, vueQueryState.value);
    });
  }
});
