import revive_payload_client_Hjmf4WeR2N from "/home/runner/work/wision/wision/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ySWanUoDla from "/home/runner/work/wision/wision/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Or2oaplyd8 from "/home/runner/work/wision/wision/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_rpN6vpLoWw from "/home/runner/work/wision/wision/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_I8AhPB5cLY from "/home/runner/work/wision/wision/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/wision/wision/packages/app/wision-app-ssr/.nuxt/components.plugin.mjs";
import prefetch_client_GGXNTOA1qA from "/home/runner/work/wision/wision/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_f1SKBejfl8 from "/home/runner/work/wision/wision/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import view_transitions_client_cHxL8iTm4O from "/home/runner/work/wision/wision/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_Wpj0cDp0U1 from "/home/runner/work/wision/wision/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import api_client_KiyocXJS6U from "/home/runner/work/wision/wision/packages/app/wision-app-ssr/src/plugins/api-client.ts";
import sentry_h0fMjCG9AB from "/home/runner/work/wision/wision/packages/app/wision-app-ssr/src/plugins/sentry.ts";
import vue_query_JC9gnf7CA1 from "/home/runner/work/wision/wision/packages/app/wision-app-ssr/src/plugins/vue-query.ts";
export default [
  revive_payload_client_Hjmf4WeR2N,
  unhead_ySWanUoDla,
  router_Or2oaplyd8,
  payload_client_rpN6vpLoWw,
  check_outdated_build_client_I8AhPB5cLY,
  components_plugin_KR1HBZs4kY,
  prefetch_client_GGXNTOA1qA,
  i18n_f1SKBejfl8,
  view_transitions_client_cHxL8iTm4O,
  chunk_reload_client_Wpj0cDp0U1,
  api_client_KiyocXJS6U,
  sentry_h0fMjCG9AB,
  vue_query_JC9gnf7CA1
]