import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin({
    name: 'sentry',
    parallel: true,
    async setup(nuxtApp) {
        const vueApp = nuxtApp.vueApp;

        const config = useRuntimeConfig();

        Sentry.init({
            app: vueApp,
            dsn: config.public.SENTRY_DSN_PUBLIC,
            integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration(), Sentry.captureConsoleIntegration()],
            tracesSampleRate: config.public.SENTRY_TRACES_SAMPLE_RATE as number,
            replaysSessionSampleRate: config.public.SENTRY_REPLAY_SAMPLE_RATE as number,
            replaysOnErrorSampleRate: config.public.SENTRY_ERROR_REPLAY_SAMPLE_RATE as number,
            tracePropagationTargets: ['localhost', /^https:\/\/api\.wision\.dev\/api/, /^https:\/\/api\.wision\.io\/api/],
        });
    }
});
