import useApiClient, { useRequestInit } from './apiClient';
import { LoginResponse } from '@wisionmonorepo/api-client-v2/src/responses';
import { Session } from '@wisionmonorepo/wision-core-service/src/core/model/session';
import { Sessions } from '@wisionmonorepo/wision-core-service/src/core/model/dto/session';
import { Request } from '@user-service/features/authorization/model';
import { Organization } from '@user-service/features/organization/model';

export const useAuthApi = (
  baseUrl: string,
  ssr = false,
  sessionCookie: string | null = null
) => {
  const apiClient = useApiClient(ssr);
  const getRequestInit = useRequestInit(ssr, sessionCookie);

  const login = async (username: string, password: string) =>
    await apiClient<LoginResponse>(
      `${baseUrl}/api/v2/auth/login`,
      getRequestInit({
        method: 'POST',
        body: JSON.stringify({ username, password }),
        headers: {
          'Content-type': 'application/json',
        },
      })
    );

  const getAuthSession = async () =>
    await apiClient<Session>(
      `${baseUrl}/api/v2/auth`,
      getRequestInit({
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const getSessions = async () =>
    await apiClient<Sessions>(
      `${baseUrl}/api/v2/auth/session`,
      getRequestInit({
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const getAuthorizedOrganizations = async (request: Request) =>
    await apiClient<Organization[]>(
      `${baseUrl}/api/v3/user/authorization/getAuthorizedOrganizations?action=${request.action}&resource=${request.resource}&item=${request.item ?? ''}`,
      getRequestInit({
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
    );

  const logoutSession = async (session: string) =>
    await apiClient(
      `${baseUrl}/api/v2/auth/session/logout/${session}`,
      getRequestInit({
        method: 'DELETE',
      }),
      false
    );

  const logout = async () =>
    await apiClient(
      `${baseUrl}/api/v2/auth/logout`,
      getRequestInit({
        method: 'GET',
      }),
      false
    );

  return {
    login,
    logout,
    logoutSession,
    getAuthSession,
    getSessions,
    getAuthorizedOrganizations,
  };
};
