import { default as accounts4XH0eOxFzAMeta } from "/home/runner/work/wision/wision/packages/app/wision-app-ssr/src/pages/admin/accounts.vue?macro=true";
import { default as usersRw6DLIN0PPMeta } from "/home/runner/work/wision/wision/packages/app/wision-app-ssr/src/pages/admin/users.vue?macro=true";
import { default as alarmmanagementQwmgtMQEjfMeta } from "/home/runner/work/wision/wision/packages/app/wision-app-ssr/src/pages/alarmmanagement.vue?macro=true";
import { default as _91dashboard_93dWB20CkgjTMeta } from "/home/runner/work/wision/wision/packages/app/wision-app-ssr/src/pages/dashboard/[dashboard].vue?macro=true";
import { default as index2KohrIk5CtMeta } from "/home/runner/work/wision/wision/packages/app/wision-app-ssr/src/pages/dashboard/index.vue?macro=true";
import { default as indexFBvAJXIcZyMeta } from "/home/runner/work/wision/wision/packages/app/wision-app-ssr/src/pages/devicemanagement/index.vue?macro=true";
import { default as loginSQfMpahHChMeta } from "/home/runner/work/wision/wision/packages/app/wision-app-ssr/src/pages/login.vue?macro=true";
import { default as profileSEQRP4KXiIMeta } from "/home/runner/work/wision/wision/packages/app/wision-app-ssr/src/pages/profile.vue?macro=true";
import { default as taskmanagementXq0FenBe0VMeta } from "/home/runner/work/wision/wision/packages/app/wision-app-ssr/src/pages/taskmanagement.vue?macro=true";
import { default as component_45stubg1Zv9oBGJnMeta } from "/home/runner/work/wision/wision/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubg1Zv9oBGJn } from "/home/runner/work/wision/wision/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "admin-accounts",
    path: "/admin/accounts",
    meta: {"middleware":["auth","lang"]},
    component: () => import("/home/runner/work/wision/wision/packages/app/wision-app-ssr/src/pages/admin/accounts.vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "/admin/users",
    meta: {"middleware":["auth","lang"]},
    component: () => import("/home/runner/work/wision/wision/packages/app/wision-app-ssr/src/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    name: "alarmmanagement",
    path: "/alarmmanagement",
    meta: {"middleware":["auth","lang"]},
    component: () => import("/home/runner/work/wision/wision/packages/app/wision-app-ssr/src/pages/alarmmanagement.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-dashboard",
    path: "/dashboard/:dashboard()",
    meta: { ...(_91dashboard_93dWB20CkgjTMeta || {}), ...{"middleware":["auth","lang"]} },
    component: () => import("/home/runner/work/wision/wision/packages/app/wision-app-ssr/src/pages/dashboard/[dashboard].vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: { ...(index2KohrIk5CtMeta || {}), ...{"middleware":["auth","lang"]} },
    component: () => import("/home/runner/work/wision/wision/packages/app/wision-app-ssr/src/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "devicemanagement",
    path: "/devicemanagement",
    meta: {"middleware":["auth","lang"]},
    component: () => import("/home/runner/work/wision/wision/packages/app/wision-app-ssr/src/pages/devicemanagement/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: {"middleware":["lang"]},
    component: () => import("/home/runner/work/wision/wision/packages/app/wision-app-ssr/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: {"middleware":["auth","lang"]},
    component: () => import("/home/runner/work/wision/wision/packages/app/wision-app-ssr/src/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "taskmanagement",
    path: "/taskmanagement",
    meta: {"middleware":["auth","lang"]},
    component: () => import("/home/runner/work/wision/wision/packages/app/wision-app-ssr/src/pages/taskmanagement.vue").then(m => m.default || m)
  },
  {
    name: component_45stubg1Zv9oBGJnMeta?.name,
    path: "/",
    component: component_45stubg1Zv9oBGJn
  },
  {
    name: component_45stubg1Zv9oBGJnMeta?.name,
    path: "/admin",
    component: component_45stubg1Zv9oBGJn
  }
]